import { Table } from 'react-bootstrap'
import { FaRegCheckCircle } from 'react-icons/fa'

const NewsletterUsersTable = ({ currentNewsletterUsers, newsletterData }) => {
  return (
    <div className='newsletter-table-box'>
      {currentNewsletterUsers.length === 0
        ? (
          <h4>{newsletterData.newsletter_no_user_registered_yet}</h4>
          )
        : (
          <Table responsive striped bordered hover>
            <thead>
              <tr>
                <th><strong>Nº</strong></th>
                {Object.keys(currentNewsletterUsers[0] || {}).map((key) => (
                  <th key={key}>
                    {key === 'username'
                      ? (
                        <strong>{newsletterData.newsletter_table_header_username}</strong>
                        )
                      : key === 'email'
                        ? (
                          <strong>{newsletterData.newsletter_table_header_email}</strong>
                          )
                        : key === 'language'
                          ? (
                            <strong>{newsletterData.newsletter_table_header_language}</strong>
                            )
                          : key === 'isEmailVerifyed'
                            ? (
                              <strong>{newsletterData.newsletter_table_header_verifyed}</strong>
                              )
                            : (
                              <strong>{key}</strong>
                              )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {currentNewsletterUsers.map((user, index) => (
                <tr key={index}>
                  <td><strong>{index + 1}</strong></td>
                  {Object.entries(user).map(([key, value], valueIndex) => (
                    <td
                      key={valueIndex}
                      className={typeof value === 'boolean' ? 'is-email-verifyed' : ''}
                    >
                      {typeof value === 'boolean'
                        ? (
                            value
                              ? <FaRegCheckCircle className='verifyed-true' />
                              : <FaRegCheckCircle className='verifyed-false' />
                          )
                        : (
                            value
                          )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
          )}
    </div>
  )
}

export default NewsletterUsersTable
