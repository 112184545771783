import { useIntl } from 'react-intl'

export const useUpdateLanguageFormData = () => {
  const intl = useIntl()

  const updateLanguageIntlFormData = [
    {
      id: 'language',
      type: 'select',
      label: intl.formatMessage({ id: 'first-time-modal-home-form-language-label' }),
      info: intl.formatMessage({ id: 'first-time-modal-home-form-language-info' }),
      options: [
        { label: intl.formatMessage({ id: 'first-time-modal-home-form-language-select' }), value: '' },
        { label: intl.formatMessage({ id: 'first-time-modal-home-form-language-english' }), value: 'english' },
        { label: intl.formatMessage({ id: 'first-time-modal-home-form-language-spanish' }), value: 'spanish' },
        { label: intl.formatMessage({ id: 'first-time-modal-home-form-language-german' }), value: 'german' }
      ]
    }
  ]

  const updateLanguageIntlErrors = {
    language_required: intl.formatMessage({ id: 'first-time-modal-home-form-language-required' })
  }

  const updateLanguageIntlMessages = {
    submitting_message: intl.formatMessage({ id: 'update-language-submitting-form' }),
    success_message: intl.formatMessage({ id: 'update-language-success-message' })
  }

  const updateLanguageIntlButtons = {
    change_language_button: intl.formatMessage({ id: 'update-language-submit-button' })
  }

  const updateLanguageIntlInfo = {
    change_language_title: intl.formatMessage({ id: 'update-language-title' })
  }

  const updateLanguageInitialValues = {
    language: ''
  }

  return {
    updateLanguageIntlFormData,
    updateLanguageIntlErrors,
    updateLanguageIntlMessages,
    updateLanguageIntlButtons,
    updateLanguageIntlInfo,
    updateLanguageInitialValues
  }
}
