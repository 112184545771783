const cateringForm = {
  'catering-form-name-label': 'First Name',
  'catering-form-name-placeholder': 'Your name here...',
  'catering-form-name-error-required': 'Name is required',
  'catering-form-surname-label': 'Last Name',
  'catering-form-surname-placeholder': 'Your last name here...',
  'catering-form-surname-error-required': 'Surname is required',
  'catering-form-company-label': 'Company',
  'catering-form-company-placeholder': 'Your company name here...',
  'catering-form-company-address-label': 'Company Address',
  'catering-form-company-address-placeholder': 'Your company address here...',
  'catering-form-email-label': 'Email Address',
  'catering-form-email-placeholder': 'Your email address here...',
  'catering-form-email-error-invalid': 'Invalid email address',
  'catering-form-email-error-required': 'Email is required',
  'catering-form-phone-number-label': 'Phone Number',
  'catering-form-phone-number-placeholder': 'Your phone number here...',
  'catering-form-phone-number-error-invalid': 'Only numbers are allowed',
  'catering-form-phone-number-error-required': 'Phone number is required',
  'catering-form-date-label': 'Date',
  'catering-form-date-placeholder': 'Select date',
  'catering-form-date-error-invalid': 'The date must be today or later',
  'catering-form-date-error-required': 'Date is required',
  'catering-form-time-start-label': 'Start Time (approx)',
  'catering-form-time-start-placeholder': 'Select a time',
  'catering-form-time-start-error-required': 'Start time is required',
  'catering-form-time-finish-label': 'Finish Time (approx)',
  'catering-form-time-finish-placeholder': 'Select a time',
  'catering-form-time-finish-error-required': 'Finish time is required',
  'catering-form-type-of-event-label': 'Type of Event',
  'catering-form-type-of-event-placeholder': 'Describe type of event here...',
  'catering-form-type-of-event-error-required': 'Type of event is required',
  'catering-form-event-location-label': 'Event Location',
  'catering-form-event-location-placeholder': 'Event location here...',
  'catering-form-event-location-error-required': 'Event location is required',
  'catering-form-number-of-people-label': 'Number of People',
  'catering-form-number-of-people-placeholder': 'Number of people here...',
  'catering-form-number-of-people-error-min': 'Number of people must be at least 1',
  'catering-form-number-of-people-error-invalid': 'Only numbers are allowed',
  'catering-form-number-of-people-error-required': 'Number of people is required',
  'catering-form-message-label': 'Message to Us',
  'catering-form-message-placeholder': 'Write your message here...',
  'catering-form-message-error-required': 'Message is required',
  'catering-sending-form-message': 'Sending your message, please wait',
  'catering-success-message': 'Your email has been successfully sent. We will contact you shortly. Thank you for choosing us'
}

export default cateringForm
