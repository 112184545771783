import React from 'react'
import { useNavigate } from 'react-router-dom'
import PATHS from '../../../../store/models/routes'
import PrimaryButton from '../../../atoms/buttons/PrimaryButton'
import { PanditoChariotIcon } from '../../../../images/Icons/icons'
import BeigeButton from '../../../atoms/buttons/BeigeButton'
import { FormattedMessage } from 'react-intl'

const AdminRoot = () => {
  const navigate = useNavigate()

  return (
    <div className='template-box admin'>
      <div className='admin-template'>
        <div onClick={() => navigate(PATHS.ADMIN.GEOLOCATION)}>
          <PrimaryButton type='button'>
            <FormattedMessage id='geolocation-button' />
            <div className='pandito-icon-map'>
              <img src={PanditoChariotIcon} alt='pandito-icon-map' />
            </div>
          </PrimaryButton>
        </div>
        <div onClick={() => navigate(PATHS.ADMIN.NEWSLETTER)}>
          <BeigeButton type='button'>
            <FormattedMessage id='newsletter-button' />
          </BeigeButton>
        </div>
      </div>
    </div>
  )
}

export default AdminRoot
