const userErrorCodes = {
  'email-not-valid': 'correo electrónico no válido',
  'email-already-in-use': 'correo electrónico ya en uso',
  'password-invalid-length': 'longitud de contraseña inválida',
  'password-not-valid': 'contraseña no válida',
  'email-not-registrated': 'correo electrónico no registrado',
  'server-error-proccesing-password': 'error del servidor procesando la contraseña',
  'server-error-generating-token': 'error del servidor generando el token',
  'error-while-creating-user': 'error mientras se crea el usuario',
  'invalid-credentials': 'credenciales inválidas',
  'token-has-expired': 'el token ha expirado',
  'server-error-checking-credentials': 'error del servidor verificando las credenciales',
  'unknknown-error-while-verifying-token': 'error desconocido mientras se verifica el token',
  'no-more-users-registrations-allowed': 'no se permiten más registros de usuarios',
  'admin-access-only': 'Solo acceso de administrador',
  'unknown-error-while-verifying-token': 'Error desconocido al verificar el token'
}

export default userErrorCodes
