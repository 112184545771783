import React, { useEffect, useState } from 'react'
import { APIProvider, Map } from '@vis.gl/react-google-maps'
import PanditoMarkers from './PanditoMarkers.jsx'
import { Col, Row } from 'react-bootstrap'
import getPanditoLocationNow from './getPanditoLocationNow.js'
import locationsJson from './locations.json'
import { createPanditoLocationSlice } from '../../../store/slices/createPanditoLocationSlice.js'
import handleLocations from './HandleLocation.js'
import LocationTitle from './LocationTitle.jsx'

const Locations = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY

  const { panditoLocationNow } = createPanditoLocationSlice()

  const [locations, setLocations] = useState(locationsJson)

  const averageMapCenter = locations.reduce((accumulator, currentValue) => {
    return {
      averageLat: accumulator.averageLat + currentValue.coordinates.lat,
      averageLng: accumulator.averageLng + currentValue.coordinates.lng
    }
  }, { averageLat: 0, averageLng: 0 })

  const averageLat = averageMapCenter.averageLat / locations.length
  const averageLng = averageMapCenter.averageLng / locations.length

  const [currentLocation, setCurrentLocation] = useState({ lat: averageLat, lng: averageLng })
  const [zoomMap, setZoomMap] = useState(13)

  useEffect(() => {
    if (panditoLocationNow) {
      getPanditoLocationNow(panditoLocationNow, locations, setLocations)
    }
  }, [panditoLocationNow, locations])

  const callHandleLocations = (location) => {
    handleLocations(location, setZoomMap, setCurrentLocation,
      currentLocation, averageLat, averageLng)
  }

  return (
    <div className='template-content'>
      <Row className='locations-box'>
        <Col className='d-flex justify-content-center align-items-center'>
          <LocationTitle locations={locations} callHandleLocations={callHandleLocations} />
        </Col>
        <Col id='google-map'>
          <APIProvider apiKey={googleMapsApiKey}>
            <Map
              id='google-map2'
              zoom={zoomMap}
              center={{ lat: currentLocation.lat, lng: currentLocation.lng }}
              mapId='PANDITO_MAP'
              onCameraChanged={(ev) => {
                setCurrentLocation(ev.detail.center)
                setZoomMap(ev.detail.zoom)
              }}
            >
              <PanditoMarkers locations={locations} />
            </Map>
          </APIProvider>
        </Col>
      </Row>
    </div>
  )
}

export default Locations
