import { useIntl } from 'react-intl'

export const useGeolocationData = () => {
  const intl = useIntl()

  const geolocationErrors = {
    geolocation_token_error: intl.formatMessage({ id: 'geolocation-token-error' }),
    geolocation_not_suported_error: intl.formatMessage({ id: 'geolocation-not-suported-error' }),
    geolocation_permission_denied_error: intl.formatMessage({ id: 'geolocation-permission-denied-error' }),
    geolocation_position_unavailable_error: intl.formatMessage({ id: 'geolocation-position-unavailable-error' }),
    geolocation_timeout_error: intl.formatMessage({ id: 'geolocation-timeout-error' }),
    geolocation_default_error: intl.formatMessage({ id: 'geolocation-default-error' })
  }

  const geolocationSuccess = {
    geolocation_location_updated_success: intl.formatMessage({ id: 'geolocation-location-updated-success' })
  }

  const geolocationButtons = {
    geolocation_button_share_location: intl.formatMessage({ id: 'geolocation-button-share-location' }),
    geolocation_button_stop_sharing_location: intl.formatMessage({ id: 'geolocation-button-stop-sharing-location' }),
    geolocation_button_see_map: intl.formatMessage({ id: 'geolocation-button-see-map' }),
    geolocation_button_reload_page: intl.formatMessage({ id: 'geolocation-button-reload-page' })
  }

  const geolocationMessages = {
    geolocation_sharing_location_message_1: intl.formatMessage({ id: 'geolocation-sharing-location-message-1' }),
    geolocation_sharing_location_message_2: intl.formatMessage({ id: 'geolocation-sharing-location-message-2' }),
    geolocation_not_sharing_location_message_span: intl.formatMessage({ id: 'geolocation-not-sharing-location-message-span' }),
    geolocation_sharing_location_message_span: intl.formatMessage({ id: 'geolocation-sharing-location-message-span' }),
    geolocation_not_sharing_location_message_button: intl.formatMessage({ id: 'geolocation-not-sharing-location-message-button' }),
    geolocation_sharing_location_message_button: intl.formatMessage({ id: 'geolocation-sharing-location-message-button' })
  }

  return { geolocationErrors, geolocationSuccess, geolocationButtons, geolocationMessages }
}
