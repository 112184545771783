import { AdvancedMarker, Pin } from '@vis.gl/react-google-maps'
import { PanditoChariotIcon } from '../../../images/Icons/icons'

const PanditoMarkers = ({ locations }) => {
  return (
    <>
      {locations.map((location, index) => (
        <AdvancedMarker
          key={index}
          position={location.coordinates}
        >
          {location.title === 'pandito location now'
            ? (
              <div className='pandito-icon-map'>
                <img src={PanditoChariotIcon} alt='pandito-icon-map' />
              </div>
              )
            : (
              <Pin background='#EA4335' glyphColor='#000' borderColor='#000' />
              )}
        </AdvancedMarker>
      ))}
    </>
  )
}

export default PanditoMarkers
