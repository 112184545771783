import SliderChild from '../molecules/SliderChild'
import { BlackAndWhiteRainbowIcon, IceCreamIcon, PanditoChariotIcon } from '../../images/Icons/icons'
import PATHS from '../../store/models/routes.js'
import './organism.css'

const SliderParent = () => {
  const sliderItems = [
    {
      index: 1
    },
    {
      index: 2,
      link: PATHS.ABOUT_US,
      icon: BlackAndWhiteRainbowIcon,
      content: true
    },
    {
      index: 3,
      link: PATHS.OUR_PRODUCTS,
      icon: IceCreamIcon,
      content: true
    },
    {
      index: 4,
      link: PATHS.CATERING,
      icon: PanditoChariotIcon,
      content: true
    },
    {
      index: 5
    }
  ]

  return (
    <div id='slider-parent-finder' className='slider-parent'>
      {sliderItems.map(item => (
        <SliderChild key={item.index} item={item} />
      ))}

    </div>
  )
}

export default SliderParent
