import getService from '../../../../../services/get_services/getService.js'

const getLocationFromBackend = async (setPanditoLocationNow) => {
  // get locations from backend
  const urlGet = '/geolocation/get-current-location'
  const getLocationResult = await getService(urlGet)

  if (getLocationResult.success) {
    let lat = getLocationResult.data.geolocationLat
    let lng = getLocationResult.data.geolocationLng
    // if lat and lng not null, turn them to float
    if (lat !== null && lng !== null) {
      lat = parseFloat(lat)
      lng = parseFloat(lng)
    }
    setPanditoLocationNow({ lat, lng })
  }
}

export default getLocationFromBackend
