import React from 'react'
import PropTypes from 'prop-types'

const PrimaryButton = (props) => {
  return (
    <div>
      <button disabled={props.disabled} className='button primary-button' type={props.type}>{props.children}</button>
    </div>
  )
}

PrimaryButton.propTypes = {
  type: PropTypes.node.isRequired
}

export default PrimaryButton
