import { create } from 'zustand'

const locale = localStorage.getItem('locale') || 'de'

export const createLenguageManagerSlice = create((set, get) => ({

  locale,

  handleLanguage: (lenguage) => set({ locale: lenguage })

}))
