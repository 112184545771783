const newsletter = {
  'newsletter-table-header-username': 'Benutzername',
  'newsletter-table-header-email': 'E-Mail',
  'newsletter-table-header-language': 'Sprache',
  'newsletter-table-header-verifyed': 'Verifiziert',
  'newsletter-title': 'Newsletter',
  'newsletter-no-users-to-show': 'Keine Benutzer zum Anzeigen',
  'newsletter-show-english-users-button': 'Zeige englische Benutzer',
  'newsletter-show-spanish-users-button': 'Zeige spanische Benutzer',
  'newsletter-show-german-users-button': 'Zeige deutsche Benutzer',
  'newsletter-show-all-users-button': 'Alle anzeigen',
  'newsletter-show-users-component-button': 'Benutzer anzeigen',
  'newsletter-hide-users-component-button': 'Benutzer verbergen',
  'newsletter-get-users-button': 'Benutzer laden',
  'newsletter-show-form-component': 'Formular anzeigen',
  'newsletter-hide-form-component': 'Formular verbergen',
  'newsletter-no-user-registered-yet': 'Noch keine Benutzer für diese Sprache registriert'
}

export default newsletter
