import * as Yup from 'yup'
import { useCateringFormData } from './useCateringFormData.js'

export const useCateringYupValidations = () => {
  const { cateringIntlErrors } = useCateringFormData()

  function getOnlyDate (date) {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
  }

  const today = getOnlyDate(new Date())

  const registerSchema = Yup.object().shape({
    name: Yup.string()
      .required(cateringIntlErrors.name_required),
    surname: Yup.string()
      .required(cateringIntlErrors.surname_required),
    company: Yup.string(),
    companyAddress: Yup.string(),
    email: Yup.string()
      .email(cateringIntlErrors.email_invalid)
      .required(cateringIntlErrors.email_required),
    phoneNumber: Yup.string()
      .matches(/^[0-9]+$/, cateringIntlErrors.phone_number_invalid)
      .required(cateringIntlErrors.phone_number_required),
    date: Yup.date()
      .required(cateringIntlErrors.date_required)
      .min(today, cateringIntlErrors.date_invalid),
    startTime: Yup.string()
      .required(cateringIntlErrors.start_time_required),
    finishTime: Yup.string()
      .required(cateringIntlErrors.finish_time_required),
    typeOfEvent: Yup.string()
      .required(cateringIntlErrors.type_of_event_required),
    location: Yup.string()
      .required(cateringIntlErrors.event_location_required),
    numberOfPeople: Yup.number()
      .min(1, cateringIntlErrors.number_of_people_min)
      .typeError(cateringIntlErrors.number_of_people_invalid)
      .required(cateringIntlErrors.number_of_people_required),
    message: Yup.string()
      .required(cateringIntlErrors.message_required)
  })

  return { registerSchema }
}
