import React, { forwardRef, useState } from 'react'
import { Formik, Form } from 'formik'
import { useCateringFormData } from './useCateringFormData.js'
import { useCateringYupValidations } from './useCateringYupValidations.js'
import CateringMapFormData from './CateringMapFormData.jsx'
import { createMessageSlice } from '../../../store/slices/createMessageSlice.js'
import useBackendErrorsMessages from '../../molecules/messageManager/useBackendErrorsMessages.js'
import { backendErrorMessageProcessor } from '../../molecules/messageManager/backendErrorMessageProcessor.js'
import postService from '../../../services/post_services/postService.js'
import BeigeButton from '../../atoms/buttons/BeigeButton.jsx'

const CateringForm = forwardRef((props, formRef) => {
  const { cateringIntlFormData, cateringIntlMessages, cateringIntlButtons, cateringInitialValues } = useCateringFormData()
  const { registerSchema } = useCateringYupValidations()
  const [sendingEmail, setSendingEmail] = useState(false)
  const { addMessage } = createMessageSlice()
  const { backendErrors } = useBackendErrorsMessages()

  const handleFormSubmit = async (values, { resetForm }) => {
    setSendingEmail(true)
    addMessage({ type: 'submitting', content: cateringIntlMessages.submittingMessage })
    const url = '/catering/send-email'
    const response = await postService(url, values)
    if (response.success) {
      addMessage({ type: 'success', content: cateringIntlMessages.successMessage })
      resetForm()
    } else {
      const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors)
      addMessage({ type: 'error', content: errorsTranslated })
    }
    setSendingEmail(false)
  }

  return (
    <div ref={formRef}>
      <Formik initialValues={cateringInitialValues} validationSchema={registerSchema} onSubmit={handleFormSubmit}>
        {({ errors, touched }) => (
          <Form className='form'>
            <CateringMapFormData cateringIntlFormData={cateringIntlFormData} errors={errors} touched={touched} />
            <div className='form-button'>
              <BeigeButton type='submit' disabled={sendingEmail} id='largePrimaryButtonForLogin'>
                {cateringIntlButtons.buttonSend}
              </BeigeButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}
)

export default CateringForm
