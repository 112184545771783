import * as Yup from 'yup'
import { useLoginFormData } from './useLoginFormData.js'

export const useLoginYupValidations = () => {
  const { loginIntlErrors } = useLoginFormData()

  const loginRegisterSchema = Yup.object().shape(
    {
      email: Yup.string()
        .email(loginIntlErrors.email_invalid)
        .required(loginIntlErrors.email_required),
      password: Yup.string()
        .required(loginIntlErrors.password_required)
    }
  )

  return { loginRegisterSchema }
}
