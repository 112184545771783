import { useState } from 'react'
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton.jsx'
import { Formik, Form } from 'formik'
import { useNavigate } from 'react-router-dom'
import { useRegisterFormData } from './useRegisterFormData'
import { useRegisterYupValidations } from './useRegisterYupValidations'
import { useLoginFormData } from '../geolocation-login/useLoginFormData.js'
import { RegisterMapFormData } from './RegisterMapFormData'
import { createMessageSlice } from '../../../../../store/slices/createMessageSlice.js'
import useBackendErrorsMessages from '../../../../molecules/messageManager/useBackendErrorsMessages.js'
import PATHS from '../../../../../store/models/routes.js'
import { backendErrorMessageProcessor } from '../../../../molecules/messageManager/backendErrorMessageProcessor.js'
import postService from '../../../../../services/post_services/postService.js'

// TODO: In a future, this should be a RegisterUserForm for all users. And geolocation will redirect to the new .jsx. Change paths in that case.
const RegisterForm = () => {
  const { registerIntlFormData, registerInitialValues, registerIntlButtons, registerFormMessage } = useRegisterFormData()
  const { registerRegisterSchema } = useRegisterYupValidations()
  const { loginIntlButtons } = useLoginFormData()
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [submittingForm, setSubmittingForm] = useState(false)
  const { addMessage } = createMessageSlice()
  const { backendErrors } = useBackendErrorsMessages()
  const navigate = useNavigate()

  const handleFormSubmit = async (values) => {
    setSubmittingForm(true)
    addMessage({ type: 'submitting', content: registerFormMessage.submittingMessage })
    const url = '/auth/register-user'
    const response = await postService(url, values)
    if (response.success) {
      addMessage({ type: 'success', content: registerFormMessage.successMessage })
      navigate(PATHS.ADMIN.GEOLOCATION_LOGIN)
    } else {
      const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors)
      addMessage({ type: 'error', content: errorsTranslated })
    }
    setSubmittingForm(false)
  }

  return (
    <div className='form-box'>
      <Formik
        initialValues={registerInitialValues}
        validationSchema={registerRegisterSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched }) => (
          <Form className='form'>
            <RegisterMapFormData
              registerIntlFormData={registerIntlFormData}
              showPassword={showPassword}
              setShowPassword={setShowPassword}
              showConfirmPassword={showConfirmPassword}
              setShowConfirmPassword={setShowConfirmPassword}
              errors={errors}
              touched={touched}
            />
            <PrimaryButton type='submit' disabled={submittingForm}>
              {registerIntlButtons.buttonSend}
            </PrimaryButton>
            <a style={{ marginTop: '20px', textDecoration: 'none' }} href={PATHS.GEOLOCATION_LOGIN}>
              {loginIntlButtons.buttonSend}
            </a>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default RegisterForm
