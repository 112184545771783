import React from 'react'
import PropTypes from 'prop-types'

const BeigeButton = (props) => {
  return (
    <div>
      <button disabled={props.disabled} className='button beige-button' type={props.type}>{props.children}</button>
    </div>
  )
}

BeigeButton.propTypes = {
  type: PropTypes.node.isRequired
}

export default BeigeButton
