import React from 'react'
import { IoMdCloseCircle } from 'react-icons/io'

const EmailFailed = ({ newsletterFormMessages, setShowEmailFailedList, emailFailedList }) => {
  return (
    <div className='emails-failed-list-show'>
      <div className='emails-failed-list-title-box'>
        <h2>{newsletterFormMessages.newsletter_some_email_deliver_failed}</h2>
        <IoMdCloseCircle onClick={() => setShowEmailFailedList(false)} className='error' />
      </div>
      <ul>
        {emailFailedList.map((email, index) => (
          <li className='error' key={index}>{email}</li>
        ))}
      </ul>
    </div>
  )
}

export default EmailFailed
