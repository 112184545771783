const geolocation = {
  'geolocation-token-error': 'Ungültige Anmeldedaten. Bitte einloggen.',
  'geolocation-not-suported-error': 'Geolokalisierung wird von Ihrem Browser nicht unterstützt',
  'geolocation-permission-denied-error': 'Benutzer hat die Anfrage zur Geolokalisierung abgelehnt',
  'geolocation-position-unavailable-error': 'Standortinformationen sind nicht verfügbar',
  'geolocation-timeout-error': 'Die Anfrage zur Standortbestimmung des Benutzers ist abgelaufen',
  'geolocation-default-error': 'Ein unbekannter Fehler ist aufgetreten',
  'geolocation-location-updated-success': 'Standort erfolgreich aktualisiert',
  'geolocation-sharing-location-message-1': 'Sie ',
  'geolocation-sharing-location-message-2': 'teilen derzeit Ihren Standort',
  'geolocation-not-sharing-location-message-span': 'TEILEN NICHT ',
  'geolocation-sharing-location-message-span': 'TEILEN ',
  'geolocation-not-sharing-location-message-button': 'Klicken Sie unten auf die Schaltfläche, um es zu teilen',
  'geolocation-sharing-location-message-button': 'Klicken Sie unten auf die Schaltfläche, um es nicht mehr zu teilen',
  'geolocation-button-share-location': 'Aktuellen Standort teilen',
  'geolocation-button-stop-sharing-location': 'Standortfreigabe beenden',
  'geolocation-button-see-map': 'Karte anzeigen',
  'geolocation-button-reload-page': 'Seite neu laden'
}

export default geolocation
