import { Tooltip } from 'react-tooltip'
import { useGetOpeningHours } from '../../molecules/tooltips/useGetOpeningHours'

const LocationTitle = ({ locations, callHandleLocations }) => {
  const { ApeHoursComponent, WestfeldHoursComponent } = useGetOpeningHours()

  return (
    <div>
      <ul className='locations-list'>
        {locations?.map((location, index) => (
          <li key={index} className='main-box' onClick={() => callHandleLocations(location)}>
            {location.title}
            <hr style={{ width: '100%' }} />
            {location.title.includes('Schaffhauserrheinweg')
              ? (
                <ApeHoursComponent />
                )
              : location.title.includes('Westfeld')
                ? (
                  <WestfeldHoursComponent />
                  )
                : null}
          </li>
        ))}
      </ul>

      <Tooltip id='locations-opening-hours' />
    </div>
  )
}

export default LocationTitle
