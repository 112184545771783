import { useState } from 'react'
import { MdDeleteForever } from 'react-icons/md'

const ImageUploader = ({ newsletterFormMessages, setFieldValue }) => {
  const [imagePreview, setImagePreview] = useState('')

  const handleImageChange = (e) => {
    const file = e.target.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFieldValue('message', file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()
    const file = e.dataTransfer.files[0]
    if (file) {
      const reader = new FileReader()
      reader.onloadend = () => {
        setFieldValue('message', file)
        setImagePreview(reader.result)
      }
      reader.readAsDataURL(file)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  const deleteImg = () => {
    setImagePreview('')
    setFieldValue('message', '')
  }

  return (
    <div>
      <div
        style={{
          border: '2px dashed #ccc',
          padding: '20px',
          textAlign: 'center',
          position: 'relative'
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <input
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
          id='fileInput'
          onChange={handleImageChange}
        />
        <label htmlFor='fileInput' className='img-uploader-box'>
          {imagePreview
            ? (
              <div>
                <img
                  src={imagePreview}
                  alt='Preview'
                />
                <MdDeleteForever onClick={deleteImg} className='delete-icon' />
              </div>
              )
            : (
              <p style={{ margin: '0', color: 'black' }}>
                {newsletterFormMessages.newsletter_img_loader_message}
              </p>
              )}
        </label>
      </div>
    </div>
  )
}

export default ImageUploader
