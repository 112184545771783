import * as Yup from 'yup'
import { useUpdateLanguageFormData } from './useUpdateLanguageFormData.js'

export const useUpdateLanguageYupValidations = () => {
  const { updateLanguageIntlErrors } = useUpdateLanguageFormData()

  const registerSchema = Yup.object().shape({
    language: Yup.string()
      .required(updateLanguageIntlErrors.language_required)
  })

  return { registerSchema }
}
