const getPanditoLocationNow = (panditoLocationNow, locations, setLocations) => {
  const { lng: panditoLocationNowLng, lat: panditoLocationNowLat } = panditoLocationNow

  const locationIndex = locations.findIndex(location => location.title === 'pandito location now')

  // Check if location is already in the array
  const locationNotFound = locationIndex === -1

  // Case 1: Location not found and new location is null -> No action needed
  if (locationNotFound && (panditoLocationNowLat === null || panditoLocationNowLng === null)) {
    return
  }

  // Case 2: Location not found and new location is valid -> Add new location
  if (locationNotFound) {
    setLocations([...locations, {
      coordinates: { lat: panditoLocationNowLat, lng: panditoLocationNowLng },
      title: 'pandito location now'
    }])
    return
  }

  // Case 3: Location found
  // Check if the new location is null -> Remove location
  if (panditoLocationNowLat === null || panditoLocationNowLng === null) {
    setLocations(locations.filter(location => location.title !== 'pandito location now'))
    return
  }

  // Case 4: Location found and new location is different -> Update location
  if (panditoLocationNowLat !== locations[locationIndex].coordinates.lat ||
        panditoLocationNowLng !== locations[locationIndex].coordinates.lng) {
    const updatedLocations = locations.slice() // Create a shallow copy of locations
    updatedLocations[locationIndex] = {
      coordinates: { lat: panditoLocationNowLat, lng: panditoLocationNowLng },
      title: 'pandito location now'
    }
    setLocations(updatedLocations)
  }
}

export default getPanditoLocationNow
