const firstTimeModalHomeForm = {
  'first-time-modal-home-form-name-label': 'Name',
  'first-time-modal-home-form-name-placeholder': 'Your name here...',
  'first-time-modal-home-form-name-error-min': 'Name must be at least 2 characters',
  'first-time-modal-home-form-name-error-required': 'Name is required',
  'first-time-modal-home-form-email-label': 'Email address',
  'first-time-modal-home-form-email-placeholder': 'Your email address here...',
  'first-time-modal-home-form-email-error-invalid': 'Invalid email address',
  'first-time-modal-home-form-email-error-required': 'Email address is required',
  'first-time-modal-home-form-language-label': 'Language',
  'first-time-modal-home-form-language-info': 'Choose the language in which you want to receive the news',
  'first-time-modal-home-form-language-select': 'Select...',
  'first-time-modal-home-form-language-english': 'English',
  'first-time-modal-home-form-language-spanish': 'Spanish',
  'first-time-modal-home-form-language-german': 'German',
  'first-time-modal-home-form-language-required': 'Language is required',
  'first-time-modal-home-sending-form-message': 'Subscribing, please wait...',
  'first-time-modal-home-sending-form-message-new-verification-code': 'Generating the new verification code, please wait'
}

export default firstTimeModalHomeForm
