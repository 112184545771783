import Dropdown from 'react-bootstrap/Dropdown'
import { createLenguageManagerSlice } from '../../store/slices/createLenguageManagerSlice'

const DropdownLenguagesMenu = () => {
  const { locale, handleLanguage } = createLenguageManagerSlice()

  const handleLanguageChange = (language) => {
    handleLanguage(language)
    localStorage.setItem('locale', language)
  }

  return (
    <Dropdown>
      <Dropdown.Toggle className='dropdown-lenguage-flag-box'>
        <p>
          {
          locale === 'de'
            ? 'DE'
            : locale === 'en'
              ? 'EN'
              : locale === 'es'
                ? 'ES'
                : null
        }
        </p>
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleLanguageChange('de')}>
          <p>DE</p>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleLanguageChange('en')}>
          <p>EN</p>
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleLanguageChange('es')}>
          <p>ES</p>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DropdownLenguagesMenu
