const newsletterForm = {
  'newsletter-form-to-label': 'To',
  'newsletter-form-to-spanish': 'Users in Spanish',
  'newsletter-form-to-english': 'Users in English',
  'newsletter-form-to-german': 'Users in German',
  'newsletter-form-to-test': 'Test User',
  'newsletter-form-to-error-required': 'Choose the users you want to send the email to',
  'newsletter-form-to-error-invalid': 'Selected user(s) are invalid',
  'newsletter-form-subject-label': 'Subject',
  'newsletter-form-subject-placeholder': 'Write the subject here...',
  'newsletter-form-subject-error-required': 'Write a subject',
  'newsletter-form-message-label': 'Message',
  'newsletter-form-message-error-required': 'You must upload an image',
  'newsletter-form-sending-form-message': 'Sending emails',
  'newsletter-form-img-loader-message': 'Drag the image here or click to select',
  'newsletter-form-sending-email-all-success': 'All emails have been sent successfully',
  'newsletter-form-some-email-deliver-failed': 'Some emails have failed to deliver',
  'newsletter-form-show-failed-emails-button': 'Show failed emails'
}

export default newsletterForm
