import React, { useEffect, useState } from 'react'
import NewsletterEmailSender from './NewsletterEmailSender'
import useNewsletterEmailSenderData from './useNewsletterEmailSenderData'
import NewsletterUsers from './NewsletterUsers'
import { backendErrorMessageProcessor } from '../../../molecules/messageManager/backendErrorMessageProcessor'
import getService from '../../../../services/get_services/getService'
import { createMessageSlice } from '../../../../store/slices/createMessageSlice'
import useBackendErrorsMessages from '../../../molecules/messageManager/useBackendErrorsMessages'
import { Button } from 'react-bootstrap'

const Newsletter = () => {
  const { backendErrors } = useBackendErrorsMessages()
  const { addMessage } = createMessageSlice()
  const [currentNewsletterUsers, setCurrentNewsletterUsers] = useState([])
  const [allNewsletterUsers, setAllNewsletterUsers] = useState([])

  const handleSortByLanguage = (language) => {
    return allNewsletterUsers.filter(user => user.language === language)
  }

  const showAllNewsletterUsers = () => {
    setCurrentNewsletterUsers(allNewsletterUsers)
  }

  const filterCurrentUsersByLanguage = (language) => {
    const filteredUsers = handleSortByLanguage(language)
    setCurrentNewsletterUsers(filteredUsers)
  }

  const getAllNewsletterUsers = async () => {
    const url = '/newsletter/get-all'
    const response = await getService(url)
    if (!response.success) {
      const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors)
      addMessage({ type: 'error', content: errorsTranslated })
      return
    }
    setAllNewsletterUsers(response.data.newsletterUsers)
    setCurrentNewsletterUsers(response.data.newsletterUsers)
  }

  useEffect(() => {
    getAllNewsletterUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { newsletterData } = useNewsletterEmailSenderData()

  return (
    <div className='newsletter-template'>
      <h2>{newsletterData.newsletter_title}</h2>
      {allNewsletterUsers.length > 0
        ? (
          <div>
            <NewsletterUsers
              newsletterData={newsletterData}
              filterCurrentUsersByLanguage={filterCurrentUsersByLanguage}
              currentNewsletterUsers={currentNewsletterUsers}
              showAllNewsletterUsers={showAllNewsletterUsers}
            />
            <NewsletterEmailSender />
          </div>
          )
        : (
          <div className='newsletter-no-users-box'>
            <h4>{newsletterData.newsletter_no_users_to_show}</h4>
            <Button onClick={getAllNewsletterUsers} variant='primary'>
              {newsletterData.newsletter_get_users_button}
            </Button>
          </div>
          )}
    </div>
  )
}

export default Newsletter
