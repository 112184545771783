import { useIntl } from 'react-intl'

export const useWestfieldModalData = () => {
  const intl = useIntl()

  const westfieldModalInfo = {
    modal_title: intl.formatMessage({ id: 'westfield-modal-title' }),
    modal_body_1: intl.formatMessage({ id: 'westfield-modal-body-1' }),
    modal_body_2: intl.formatMessage({ id: 'westfield-modal-body-2' }),
    modal_body_3: intl.formatMessage({ id: 'westfield-modal-body-3' })
  }

  return {
    westfieldModalInfo
  }
}
