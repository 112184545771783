import Label from '../../atoms/formParts/Label'
import { ErrorMessage, Field } from 'formik'

export const CateringMapFormData = ({ cateringIntlFormData, errors, touched }) => {
  return (
    <div className='form-group-catering'>
      <div className='form-columns'>
        {cateringIntlFormData.map((data, index) => (
          <div className='form-group' key={index}>
            <Label>{data.label}</Label>
            <Field
              className='input'
              id={data.id}
              name={data.id}
              autoComplete={data.label}
              placeholder={data.placeholder}
              type={data.type}
              as={data.id === 'message' && 'textarea'}
              style={data.id === 'message' ? { height: '200px' } : {}}
            />
            {errors[data.id] && touched[data.id] && (
              <ErrorMessage className='message error' component='div' name={data.id} />
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default CateringMapFormData
