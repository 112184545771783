const cateringForm = {
  'catering-form-name-label': 'Vorname',
  'catering-form-name-placeholder': 'Dein Vorname hier...',
  'catering-form-name-error-required': 'Vorname ist erforderlich',
  'catering-form-surname-label': 'Nachname',
  'catering-form-surname-placeholder': 'Dein Nachname hier...',
  'catering-form-surname-error-required': 'Nachname ist erforderlich',
  'catering-form-company-label': 'Firma',
  'catering-form-company-placeholder': 'Name deiner Firma hier...',
  'catering-form-company-address-label': 'Firmenadresse',
  'catering-form-company-address-placeholder': 'Adresse deiner Firma hier...',
  'catering-form-email-label': 'E-Mail-Adresse',
  'catering-form-email-placeholder': 'Deine E-Mail-Adresse hier...',
  'catering-form-email-error-invalid': 'Ungültige E-Mail-Adresse',
  'catering-form-email-error-required': 'E-Mail-Adresse ist erforderlich',
  'catering-form-phone-number-label': 'Telefonnummer',
  'catering-form-phone-number-placeholder': 'Deine Telefonnummer hier...',
  'catering-form-phone-number-error-invalid': 'Nur Zahlen sind erlaubt',
  'catering-form-phone-number-error-required': 'Telefonnummer ist erforderlich',
  'catering-form-date-label': 'Datum',
  'catering-form-date-placeholder': 'Datum auswählen',
  'catering-form-date-error-invalid': 'Das Datum muss heute oder später sein',
  'catering-form-date-error-required': 'Datum ist erforderlich',
  'catering-form-time-start-label': 'Startzeit (ca.)',
  'catering-form-time-start-placeholder': 'Wählen Sie eine Zeit',
  'catering-form-time-start-error-required': 'Startzeit ist erforderlich',
  'catering-form-time-finish-label': 'Endzeit (ca.)',
  'catering-form-time-finish-placeholder': 'Wählen Sie eine Zeit',
  'catering-form-time-finish-error-required': 'Endzeit ist erforderlich',
  'catering-form-type-of-event-label': 'Art des Events',
  'catering-form-type-of-event-placeholder': 'Beschreibe die Art des Events hier...',
  'catering-form-type-of-event-error-required': 'Art des Events ist erforderlich',
  'catering-form-event-location-label': 'Veranstaltungsort',
  'catering-form-event-location-placeholder': 'Veranstaltungsort hier...',
  'catering-form-event-location-error-required': 'Veranstaltungsort ist erforderlich',
  'catering-form-number-of-people-label': 'Anzahl der Personen',
  'catering-form-number-of-people-placeholder': 'Anzahl der Personen hier...',
  'catering-form-number-of-people-error-min': 'Anzahl der Personen muss mindestens 1 sein',
  'catering-form-number-of-people-error-invalid': 'Nur Zahlen sind erlaubt',
  'catering-form-number-of-people-error-required': 'Anzahl der Personen ist erforderlich',
  'catering-form-message-label': 'Nachricht',
  'catering-form-message-placeholder': 'Schreibe hier deine Nachricht...',
  'catering-form-message-error-required': 'Nachricht ist erforderlich',
  'catering-sending-form-message': 'Deine Nachricht wird gesendet, bitte warten',
  'catering-success-message': 'Ihre E-Mail wurde erfolgreich gesendet. Wir werden uns in Kürze mit Ihnen in Verbindung setzen. Vielen Dank, dass Sie uns gewählt haben'
}

export default cateringForm
