const ourProducts = {
  'OurProducts-1': 'Ice Cream, Ice Cream, Ice Cream',
  'OurProducts-2': '- Various flavors of homemade ice cream',
  'OurProducts-3': 'Coffee specialties',
  'OurProducts-4': 'Various cold beverages',
  'OurProducts-5': 'Sweet pastries',
  'OurProducts-6': 'Wickelfische in various colors and sizes',
  'OurProducts-7': 'Our ice cream thrives on being sometimes classic, sometimes creative, but always delicious, varied, and a must in summer. And not to forget: At Pandito, there is something for every taste! We offer a wide variety of flavors: milk ice cream, vegan ice cream, and refreshing sorbets. We love to refine our ice cream with ingredients from our homeland, Argentina. This naturally includes the delicious dulce de leche, mate tea, and the Argentine pastry Alfajor.'
}

export default ourProducts
