const headerNavbar = {
  'headerNavBar-navLink1': 'Sobre nosotros',
  'headerNavBar-navLink2': 'Nuestra oferta',
  'headerNavBar-navLink3': 'Catering',
  'headerNavBar-navLink4': 'Ubicaciones',
  'headerNavBar-navLink5': 'Tienda en línea',
  'headerNavBar-navLink6': 'Socios',
  'headerNavBar-navLink7': 'Admin'
}

export default headerNavbar
