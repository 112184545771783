import postService from '../../../../../services/post_services/postService'
import getLocationFromBackend from './getLocationFromBackend'

const handleUpdateLocation = async (
  setPanditoLocationNow,
  addMessage,
  backendErrorMessageProcessor,
  backendErrors,
  geolocationSuccess,
  location
) => {
  const urlPost = '/geolocation/set-current-location'
  const updateLocationResponse = await postService(urlPost, location)
  if (!updateLocationResponse.success) {
    const errorsTranslated = backendErrorMessageProcessor(backendErrors, updateLocationResponse.errors)
    addMessage({ type: 'error', content: errorsTranslated })
    return
  }

  addMessage({ type: 'success', content: geolocationSuccess.geolocation_location_updated_success })
  await getLocationFromBackend(setPanditoLocationNow)
}

export default handleUpdateLocation
