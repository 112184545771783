const cateringFormErrorCodes = {
  'email-not-valid': 'ungültige E-Mail-Adresse',
  'name-not-valid': 'ungültiger Name',
  'surname-not-valid': 'ungültiger Nachname',
  'company-not-valid': 'ungültiges Unternehmen',
  'company-address-not-valid': 'ungültige Unternehmensadresse',
  'phone-number-not-valid': 'ungültige Telefonnummer',
  'date-not-valid': 'ungültiges Datum',
  'time-not-valid': 'ungültige Uhrzeit',
  'type-of-event-not-valid': 'ungültiger Veranstaltungstyp',
  'location-not-valid': 'ungültiger Ort',
  'number-of-people-not-valid': 'ungültige Anzahl von Personen',
  'message-not-valid': 'ungültige Nachricht'
}

export default cateringFormErrorCodes
