const registerForm = {
  'register-form-username-label': 'Nombre de usuario',
  'register-form-username-placeholder': 'Tu nombre de usuario aquí...',
  'register-form-username-error-required': 'El nombre de usuario es obligatorio',
  'register-form-username-error-min': 'El nombre de usuario debe tener al menos 2 caracteres',
  'register-form-username-error-max': 'El nombre de usuario debe tener como máximo 50 caracteres',
  'register-form-email-label': 'Dirección de correo electrónico',
  'register-form-email-placeholder': 'Tu dirección de correo electrónico aquí...',
  'register-form-email-error-invalid': 'Dirección de correo electrónico no válida',
  'register-form-email-error-required': 'El correo electrónico es obligatorio',
  'register-form-password-label': 'Contraseña',
  'register-form-password-placeholder': 'Tu contraseña aquí...',
  'register-form-password-error-required': 'La contraseña es obligatoria',
  'register-form-password-error-invalid': 'La contraseña debe incluir al menos una letra mayúscula, una letra minúscula, un número y un carácter especial',
  'register-form-password-error-min': 'La contraseña debe tener al menos 8 caracteres',
  'register-form-password-error-max': 'La contraseña debe tener como máximo 50 caracteres',
  'register-form-password-confirmation-label': 'Confirmación de contraseña',
  'register-form-password-confirmation-placeholder': 'Tu contraseña aquí...',
  'register-form-password-confirmation-error-required': 'La confirmación de la contraseña es obligatoria',
  'register-form-password-confirmation-error-match': 'Las contraseñas deben coincidir',
  'register-form-sending-form-message': 'Creando usuario, por favor espere',
  'register-form-success-message': 'Usuario creado exitosamente'
}

export default registerForm
