const userErrorCodes = {
  'email-not-valid': 'email not valid',
  'email-already-in-use': 'email already in use',
  'password-invalid-length': 'password invalid length',
  'password-not-valid': 'password not valid',
  'email-not-registrated': 'email not registrated',
  'server-error-proccesing-password': 'server error proccesing password',
  'server-error-generating-token': 'server error generating token',
  'error-while-creating-user': 'error while creating user',
  'invalid-credentials': 'invalid credentials',
  'token-has-expired': 'token has expired',
  'server-error-checking-credentials': 'server error checking credentials',
  'unknknown-error-while-verifying-token': 'unknknown error while verifying token',
  'no-more-users-registrations-allowed': 'no more users registrations allowed',
  'admin-access-only': 'admin access only',
  'unknown-error-while-verifying-token': 'unknown-error-while-verifying-token'
}

export default userErrorCodes
