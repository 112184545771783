import React, { useState } from 'react'
import { BsInstagram, BsWhatsapp } from 'react-icons/bs'
import { IoMdMail } from 'react-icons/io'
import { Col, Container, Nav, Navbar, Row } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { IoArrowDownCircle, IoArrowUpCircle } from 'react-icons/io5'
import { FormattedMessage } from 'react-intl'
import { Tooltip } from 'react-tooltip'
import { useGetOpeningHours } from './tooltips/useGetOpeningHours'

const FooterNavBar = () => {
  const { apeHoursHtml, westfeldHoursHtml } = useGetOpeningHours()
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)

  const getFooterClass = () => {
    if (location.pathname === '/') {
      return `me-auto ${showMenu ? 'footer-nav-bar footer-nav-bar-toogled' : 'footer-nav-bar'}`
    } else {
      return `me-auto ${showMenu ? 'footer-nav-bar-templates footer-nav-bar-templates-toggled' : 'footer-nav-bar-templates'}`
    }
  }

  const handleMenuToggle = () => {
    setShowMenu(!showMenu)
  }

  return (
    <div className={getFooterClass()}>
      <Navbar expand='lg'>
        <Container className='navbar-container custom-footer-container'>
          <Navbar.Collapse id='basic-navbar-nav' className='custom-collapse'>
            <Nav className='me-auto custom-footer-navbar'>
              <Row>
                <Col className='footer-navbar-col-buttons'>
                  <a href='https://wa.me/41774091740' target='_blank' rel='noreferrer'>
                    <BsWhatsapp className='footer-icon' />
                  </a>
                  <a href='https://instagram.com/panditoicecream' target='_blank' rel='noreferrer'>
                    <BsInstagram className='footer-icon' />
                  </a>
                  <a href='mailto:panditoicecream@gmail.com'>
                    <IoMdMail className='footer-icon' />
                  </a>
                </Col>
              </Row>
              <Row className='description-footer-box'>
                <Col md={12} xl={4} className='footer-navbar-col'>
                  <p>
                    <FormattedMessage id='footerNavBar-1' />
                  </p>
                  <p>
                    <FormattedMessage id='footerNavBar-2' />
                  </p>
                </Col>
                <Col md={12} xl={4} className='footer-navbar-col'>
                  <p
                    data-tooltip-id='tooltip-footer-3'
                    data-tooltip-html={apeHoursHtml()}
                    data-tooltip-place='top'
                  >
                    <FormattedMessage id='footerNavBar-3' />
                  </p>
                  <Tooltip id='tooltip-footer-3' />

                  <p
                    data-tooltip-id='tooltip-footer-4'
                    data-tooltip-html={westfeldHoursHtml()}
                    data-tooltip-place='top'
                  >
                    <FormattedMessage id='footerNavBar-4' />
                  </p>

                  <Tooltip id='tooltip-footer-4' />
                </Col>
                <Col md={12} xl={4} className='footer-navbar-col'>
                  <p>
                    <FormattedMessage id='footerNavBar-5' /><FormattedMessage id='footerNavBar-6' />
                  </p>
                  <p className='designed-by-box'>
                    <a href='https://www.linkedin.com/in/emiliano-zappa-153551266/' target='_blank' rel='noreferrer'>
                      <FormattedMessage id='footerNavBar-7' />
                    </a>
                  </p>
                </Col>
              </Row>
            </Nav>
          </Navbar.Collapse>
          <Navbar.Toggle aria-controls='basic-navbar-nav' className='navbar-toogle' onClick={handleMenuToggle}>
            {showMenu
              ? <IoArrowDownCircle />
              : <IoArrowUpCircle />}
          </Navbar.Toggle>
        </Container>
      </Navbar>
    </div>
  )
}

export default FooterNavBar
