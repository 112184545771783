import { Button, Modal } from 'react-bootstrap'
import DropdownLenguagesMenu from '../../../DropdownLenguagesMenu'
import { useWestfieldModalData } from './useWestfieldModalData.js'
import { FaRegSmileBeam } from 'react-icons/fa'

const WestfieldModal = ({
  showWestfieldModal,
  setShowWestfieldModal
}) => {
  // todo: Poner carita feliz al final
  const { westfieldModalInfo } = useWestfieldModalData()
  return (
    <Modal className='westfield-modal' size='xl' show={showWestfieldModal} onHide={() => setShowWestfieldModal(false)}>
      <div id='westfield-modal-image'>
        <div className='westfield-modal-main'>
          <Modal.Header>
            <Modal.Title className='westfield-modal-title brand-name-font-style row-box'>
              <DropdownLenguagesMenu />
              <h1>{westfieldModalInfo.modal_title}</h1>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className='modal-info'>
            <div className='p-container'>
              <p>{westfieldModalInfo.modal_body_1}</p>
              <p>{westfieldModalInfo.modal_body_2}</p>
              <p>{westfieldModalInfo.modal_body_3} <FaRegSmileBeam /></p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowWestfieldModal(false)} variant='outline-light'>OK</Button>
          </Modal.Footer>
        </div>
      </div>
    </Modal>
  )
}

export default WestfieldModal
