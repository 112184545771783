const newsletter = {
  'newsletter-table-header-username': 'Usuario',
  'newsletter-table-header-email': 'Email',
  'newsletter-table-header-language': 'Idioma',
  'newsletter-table-header-verifyed': 'Verificado',
  'newsletter-title': 'Boletin informativo',
  'newsletter-no-users-to-show': 'No hay usuarios para mostrar',
  'newsletter-show-english-users-button': 'Mostrar usuarios inglés',
  'newsletter-show-spanish-users-button': 'Mostrar usuarios español',
  'newsletter-show-german-users-button': 'Mostrar usuarios alemán',
  'newsletter-show-all-users-button': 'Mostrar todos',
  'newsletter-show-users-component-button': 'Mostrar usuarios',
  'newsletter-hide-users-component-button': 'Ocultar usuarios',
  'newsletter-get-users-button': 'Cargar usuarios',
  'newsletter-show-form-component': 'Mostrar formulario',
  'newsletter-hide-form-component': 'Ocultar formulario',
  'newsletter-no-user-registered-yet': 'No hay usuarios registrados para este idioma aun'
}

export default newsletter
