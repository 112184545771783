import { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import { createMessageSlice } from '../../../store/slices/createMessageSlice.js'
import postService from '../../../services/post_services/postService.js'
import { useUpdateLanguageFormData } from './useUpdateLanguageFormData.js'
import { useUpdateLanguageYupValidations } from './useUpdateLanguageYupValidations.js'
import UpdateLanguageFormDataMap from './UpdateLanguageFormDataMap.jsx'
import WhiteButton from '../../atoms/buttons/WhiteButton.jsx'
import { useNavigate } from 'react-router-dom'
import PATHS from '../../../store/models/routes.js'
import { backendErrorMessageProcessor } from '../../molecules/messageManager/backendErrorMessageProcessor.js'
import useBackendErrorsMessages from '../../molecules/messageManager/useBackendErrorsMessages.js'

const UpdateLanguageForm = () => {
  const navigate = useNavigate()
  const { backendErrors } = useBackendErrorsMessages()
  const [email, setEmail] = useState(null)
  const [verificationToken, setVerificationToken] = useState(null)

  const {
    updateLanguageIntlFormData,
    updateLanguageIntlMessages,
    updateLanguageIntlButtons,
    updateLanguageInitialValues
  } = useUpdateLanguageFormData()

  const { registerSchema } = useUpdateLanguageYupValidations()
  const [submittingForm, setSubmittingForm] = useState(false)
  const { addMessage } = createMessageSlice()

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const verificationTokenParam = queryParams.get('token')
    const emailParam = queryParams.get('email')

    if (verificationTokenParam && emailParam) {
      setEmail(emailParam)
      setVerificationToken(verificationTokenParam)
    }
  }, [])

  const handleFormSubmit = async (values) => {
    values.email = email
    values.verificationToken = verificationToken
    setSubmittingForm(true)
    addMessage({ type: 'submitting', content: updateLanguageIntlMessages.submitting_message })
    const url = '/newsletter/update-language'
    const response = await postService(url, values)
    if (response.success) {
      addMessage({ type: 'success', content: updateLanguageIntlMessages.success_message })
      navigate(PATHS.HOME)
    } else {
      const errorsTranslated = backendErrorMessageProcessor(backendErrors, response.errors)
      addMessage({ type: 'error', content: errorsTranslated })
    }
    setSubmittingForm(false)
  }

  return (
    <div>
      <Formik
        initialValues={updateLanguageInitialValues}
        validationSchema={registerSchema}
        onSubmit={handleFormSubmit}
      >
        {({ errors, touched, values }) => (
          <Form className='form'>
            <UpdateLanguageFormDataMap
              updateLanguageIntlFormData={updateLanguageIntlFormData}
              errors={errors}
              touched={touched}
            />
            <div className='form-button'>
              <WhiteButton variant='success' type='submit' disabled={submittingForm}>
                {updateLanguageIntlButtons.change_language_button}
              </WhiteButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default UpdateLanguageForm
