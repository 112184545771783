const registerForm = {
  'register-form-username-label': 'Benutzername',
  'register-form-username-placeholder': 'Ihr Benutzername hier...',
  'register-form-username-error-required': 'Benutzername ist erforderlich',
  'register-form-username-error-min': 'Der Benutzername muss mindestens 2 Zeichen lang sein',
  'register-form-username-error-max': 'Der Benutzername darf höchstens 50 Zeichen lang sein',
  'register-form-email-label': 'E-Mail-Adresse',
  'register-form-email-placeholder': 'Ihre E-Mail-Adresse hier...',
  'register-form-email-error-invalid': 'Ungültige E-Mail-Adresse',
  'register-form-email-error-required': 'E-Mail ist erforderlich',
  'register-form-password-label': 'Passwort',
  'register-form-password-placeholder': 'Ihr Passwort hier...',
  'register-form-password-error-required': 'Passwort ist erforderlich',
  'register-form-password-error-invalid': 'Das Passwort muss mindestens einen Großbuchstaben, einen Kleinbuchstaben, eine Zahl und ein Sonderzeichen enthalten',
  'register-form-password-error-min': 'Das Passwort muss mindestens 8 Zeichen lang sein',
  'register-form-password-error-max': 'Das Passwort darf höchstens 50 Zeichen lang sein',
  'register-form-password-confirmation-label': 'Passwortbestätigung',
  'register-form-password-confirmation-placeholder': 'Ihr Passwort hier...',
  'register-form-password-confirmation-error-required': 'Passwortbestätigung ist erforderlich',
  'register-form-password-confirmation-error-match': 'Passwörter müssen übereinstimmen',
  'register-form-sending-form-message': 'Benutzer wird erstellt, bitte warten',
  'register-form-success-message': 'Benutzer erfolgreich erstellt'
}

export default registerForm
