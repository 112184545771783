const newsletter = {
  'newsletter-table-header-username': 'Username',
  'newsletter-table-header-email': 'Email',
  'newsletter-table-header-language': 'Language',
  'newsletter-table-header-verifyed': 'Verified',
  'newsletter-title': 'Newsletter',
  'newsletter-no-users-to-show': 'No users to show',
  'newsletter-show-english-users-button': 'Show English users',
  'newsletter-show-spanish-users-button': 'Show Spanish users',
  'newsletter-show-german-users-button': 'Show German users',
  'newsletter-show-all-users-button': 'Show all',
  'newsletter-show-users-component-button': 'Show users',
  'newsletter-hide-users-component-button': 'Hide users',
  'newsletter-get-users-button': 'Load users',
  'newsletter-show-form-component': 'Show form',
  'newsletter-hide-form-component': 'Hide form',
  'newsletter-no-user-registered-yet': 'No users registered for this language yet'
}

export default newsletter
