import React from 'react'
import PropTypes from 'prop-types'
import './molecules.css'
import '../../App.css'
import { AnimatePresence, motion } from 'framer-motion'
import { useNavigate } from 'react-router-dom'
import Locations from '../template/location/Locations'
import { FormattedMessage } from 'react-intl'

const SliderChild = ({ item }) => {
  const navigate = useNavigate()

  return (
    <div className={`slider-child slider-child-${item.index}`}>
      <section id={`home-section-${item.index}`}>
        {item.index === 1 && (
          <div className='section-1-box'>
            <AnimatePresence>
              <motion.div
                key={1}
                initial={{ x: '290vw', y: '0vh' }}
                transition={{ ease: 'easeOut', duration: 2.5 }}
                animate={{ x: '0vw', y: '0vh' }}
              >
                <h1 className='brand-name-font-style'>PANDITO</h1>
              </motion.div>
              <motion.div
                key={2}
                initial={{ x: '-290vw', y: '0vh' }}
                transition={{ ease: 'easeOut', duration: 2.5 }}
                animate={{ x: '0vw', y: '0vh' }}
              >
                <h3>
                  <FormattedMessage id='sliderItems-1-title-a' />
                  <br />
                  <FormattedMessage id='sliderItems-1-title-b' />
                </h3>
              </motion.div>
            </AnimatePresence>
          </div>
        )}

        {item.content && (
          <div className='colum-box'>
            <div className='center-child-box' onClick={() => navigate(item.link)}>
              <img src={item.icon} className='custom-icon' alt='icon' />
              <h3>
                <FormattedMessage id={`sliderItems-${item.index}-title`} />
              </h3>
            </div>
          </div>
        )}

        {item.index === 5 && (
          <Locations />
        )}
      </section>
    </div>
  )
}

SliderChild.propTypes = {
  item: PropTypes.shape({
    index: PropTypes.number.isRequired,
    link: PropTypes.string,
    image: PropTypes.string,
    alt: PropTypes.string,
    icon: PropTypes.string,
    content: PropTypes.bool
  }).isRequired
}

export default SliderChild
