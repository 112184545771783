import * as Yup from 'yup'
import useNewsletterEmailSenderData from './useNewsletterEmailSenderData.js'

export const useNewsletterYupValidations = () => {
  const { newsletterFormDataErrors } = useNewsletterEmailSenderData()

  const registerSchema = Yup.object().shape({
    to: Yup.string()
      .required(newsletterFormDataErrors.newsletter_form_to_error_required)
      .oneOf(['spanish', 'english', 'german', 'test'], newsletterFormDataErrors.newsletter_form_to_error_invalid),
    subject: Yup.string()
      .required(newsletterFormDataErrors.newsletter_form_subject_error_required),
    message: Yup.string()
      .required(newsletterFormDataErrors.newsletter_form_message_error_required)
  })

  return { registerSchema }
}
