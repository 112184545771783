function isValidPath (path, paths) {
  for (const key in paths) {
    if (typeof paths[key] === 'string') {
      if (paths[key] === path) return true
    } else {
      if (isValidPath(path, paths[key])) return true
    }
  }
  return false
}

export default isValidPath
