import { IntlProvider } from 'react-intl'
import messagesDe from './de/main/de.js'
import messagesEn from './en/main/en.js'
import messagesEs from './es/main/es.js'

const messages = {
  de: messagesDe,
  en: messagesEn,
  es: messagesEs
}

export function IntlManager ({ children, locale }) {
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  )
}
