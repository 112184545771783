import { useIntl } from 'react-intl'

export const useRegisterFormData = () => {
  const intl = useIntl()

  // Form values depending lenguages
  const registerIntlFormData = [
    { id: 'username', type: 'text', label: intl.formatMessage({ id: 'register-form-username-label' }), placeholder: intl.formatMessage({ id: 'register-form-username-placeholder' }) },
    { id: 'email', type: 'email', label: intl.formatMessage({ id: 'register-form-email-label' }), placeholder: intl.formatMessage({ id: 'register-form-email-placeholder' }) },
    { id: 'password', type: 'password', label: intl.formatMessage({ id: 'register-form-password-label' }), placeholder: intl.formatMessage({ id: 'register-form-password-placeholder' }) },
    { id: 'confirmationPassword', type: 'password', label: intl.formatMessage({ id: 'register-form-password-confirmation-label' }), placeholder: intl.formatMessage({ id: 'register-form-password-confirmation-placeholder' }) }
  ]

  const registerIntlErrors = {
    username_required: intl.formatMessage({ id: 'register-form-username-error-required' }),
    username_min: intl.formatMessage({ id: 'register-form-username-error-min' }),
    username_max: intl.formatMessage({ id: 'register-form-username-error-max' }),
    email_invalid: intl.formatMessage({ id: 'register-form-email-error-invalid' }),
    email_required: intl.formatMessage({ id: 'register-form-email-error-required' }),
    password_required: intl.formatMessage({ id: 'register-form-password-error-required' }),
    password_invalid: intl.formatMessage({ id: 'register-form-password-error-invalid' }),
    password_min: intl.formatMessage({ id: 'register-form-password-error-min' }),
    password_max: intl.formatMessage({ id: 'register-form-password-error-max' }),
    confirmation_password_required: intl.formatMessage({ id: 'register-form-password-confirmation-error-required' }),
    confirmation_password_match: intl.formatMessage({ id: 'register-form-password-confirmation-error-match' })
  }

  const registerIntlButtons = {
    buttonSend: intl.formatMessage({ id: 'button-register' })
  }

  const registerFormMessage = {
    submittingMessage: intl.formatMessage({ id: 'register-form-sending-form-message' }),
    successMessage: intl.formatMessage({ id: 'register-form-success-message' })
  }

  const registerInitialValues = {
    username: '',
    email: '',
    password: '',
    confirmationPassword: ''
  }

  return { registerIntlFormData, registerIntlErrors, registerIntlButtons, registerInitialValues, registerFormMessage }
}
