import { FormattedMessage, useIntl } from 'react-intl'

const Partners = () => {
  const partnersQuantity = 9 // Change this if partners quantity change in i18n
  const intl = useIntl()

  return (
    <div className='template-partners-box'>
      <div className='partners-template'>
        <div className='partners-title'>
          <h1><FormattedMessage id='partners-main-title' /></h1>
        </div>
        <div className='partners-box'>
          <div className='partners-column'>
            {Array.from({ length: Math.ceil(partnersQuantity / 2) }, (_, index) => (
              <div className='partners-detail' key={index}>
                <a href={intl.formatMessage({ id: `partner-${index * 2 + 1}-link` })} target='_blank' rel='noreferrer'>
                  <h2><FormattedMessage id={`partner-${index * 2 + 1}-title`} /></h2>
                  <p><FormattedMessage id={`partner-${index * 2 + 1}-content`} /></p>
                </a>
              </div>
            ))}
          </div>
          <div className='partners-column'>
            {Array.from({ length: Math.floor(partnersQuantity / 2) }, (_, index) => (
              <div className='partners-detail' key={index}>
                <a href={intl.formatMessage({ id: `partner-${index * 2 + 2}-link` })} target='_blank' rel='noreferrer'>
                  <h2><FormattedMessage id={`partner-${index * 2 + 2}-title`} /></h2>
                  <p><FormattedMessage id={`partner-${index * 2 + 2}-content`} /></p>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partners
