const newsletterErrorCodes = {
  'email-not-valid': 'ungültige E-Mail-Adresse',
  'name-not-valid': 'ungültiger Name',
  'email-already-in-use': 'E-Mail-Adresse bereits in Verwendung',
  'error-while-processing-newsletter-registration': 'Fehler bei der Verarbeitung der Newsletter-Anmeldung',
  'user-already-veryficated': 'Benutzer bereits verifiziert',
  'email-not-registrated': 'E-Mail nicht registriert',
  'server-error-while-verifying-user-email': 'Serverfehler bei der Überprüfung der Benutzer-E-Mail',
  'server-error-while-verifying-user-data': 'Serverfehler bei der Überprüfung der Benutzerdaten',
  'invalid-credentials': 'Ungültige Anmeldeinformationen',
  'language-not-valid': 'Ungültige Sprache',
  'server-error-while-getting-newsletter-users': 'Serverfehler beim Abrufen der Newsletter-Benutzer',
  'subject-not-valid': 'Betreff ungültig',
  'message-not-valid': 'Nachricht ungültig',
  'some-email-delivers-failed': 'Einige E-Mail-Zustellungen fehlgeschlagen',
  'server-error-while-sending-emails': 'Serverfehler beim Senden von E-Mails',
  'no-verified-users-found-for-selected-language': 'Keine verifizierten Benutzer für die ausgewählte Sprache gefunden',
  'user-already-unsubscribed': 'Benutzer bereits abgemeldet',
  'server-error-while-unsubscribing-user': 'Serverfehler beim Abmelden des Benutzers',
  'invalid-credentials-please-try-again-later-or-contact-us': 'ungültige Anmeldeinformationen. Bitte versuchen Sie es später erneut oder kontaktieren Sie uns',
  'your-token-has-expired-we-have-sent-you-an-email-with-new-credentials': 'Ihr Token ist abgelaufen. Wir haben Ihnen eine E-Mail mit neuen Anmeldeinformationen gesendet',
  'invalid-token-we-have-sent-you-an-email-with-new-credentials': 'ungültiger Token. Wir haben Ihnen eine E-Mail mit neuen Anmeldeinformationen gesendet',
  'unknown-error-while-verifying-token-we-have-sent-you-an-email-with-new-credentials': 'unbekannter Fehler bei der Überprüfung des Tokens. Wir haben Ihnen eine E-Mail mit neuen Anmeldeinformationen gesendet',
  'server-error-while-updating-newsletter-language': 'Serverfehler beim Aktualisieren der Newsletter-Sprache',
  'the-selected-language-is-the-same-as-the-current-language': 'Die ausgewählte Sprache ist dieselbe wie die aktuelle Sprache'
}

export default newsletterErrorCodes
