import { useGeolocationData } from './useGeolocationData'
import PrimaryButton from '../../../../atoms/buttons/PrimaryButton'
import { PanditoChariotIcon } from '../../../../../images/Icons/icons'

const GeolocationNotShearing = ({ handleClickShareLocation, gettingNewLocation }) => {
  const { geolocationButtons, geolocationMessages } = useGeolocationData()

  return (
    <>
      <p>
        {geolocationMessages.geolocation_sharing_location_message_1}
        <span style={{ fontWeight: '900' }}>
          {geolocationMessages.geolocation_not_sharing_location_message_span}
        </span>
        {geolocationMessages.geolocation_sharing_location_message_2}
      </p>
      <p>
        {geolocationMessages.geolocation_not_sharing_location_message_button}
      </p>
      <div onClick={() => handleClickShareLocation(true)}>
        <PrimaryButton disabled={gettingNewLocation} type='button'>
          {geolocationButtons.geolocation_button_share_location}
          <div className='pandito-icon-map'>
            <img src={PanditoChariotIcon} alt='pandito-icon-map' />
          </div>
        </PrimaryButton>
      </div>
    </>
  )
}

export default GeolocationNotShearing
