import WestfieldModal from '../../molecules/modals/first_time_modal_home/first_time_modal_westfield/WestfieldModal'
import SliderParent from '../../organisms/SliderParent'
import { useState } from 'react'

const Home = () => {
  const [showWestfieldModal, setShowWestfieldModal] = useState(true)

  return (
    <div>
      <SliderParent />
      <WestfieldModal
        showWestfieldModal={showWestfieldModal}
        setShowWestfieldModal={setShowWestfieldModal}
      />
    </div>
  )
}

export default Home
