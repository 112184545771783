const cateringFormErrorCodes = {
  'email-not-valid': 'email not valid',
  'name-not-valid': 'name not valid',
  'surname-not-valid': 'surname not valid',
  'company-not-valid': 'company not valid',
  'company-address-not-valid': 'company address not valid',
  'phone-number-not-valid': 'phone number not valid',
  'date-not-valid': 'date not valid',
  'time-not-valid': 'time not valid',
  'type-of-event-not-valid': 'type of event not valid',
  'location-not-valid': 'location not valid',
  'number-of-people-not-valid': 'number of people not valid',
  'message-not-valid': 'message not valid'
}

export default cateringFormErrorCodes
