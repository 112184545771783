const buttons = {
  'button-send': 'Send',
  'button-cancel': 'Cancel',
  'button-login': 'Login',
  'button-register': 'Register',
  'button-subscribe': 'Subscribe',
  'button-close': 'Close',
  'button-verify': 'Open your email',
  'button-email-in-use': 'Generate a new verification code'
}

export default buttons
