import { useIntl } from 'react-intl'

export const useLoginFormData = () => {
  const intl = useIntl()

  // Form values depending lenguages
  const loginIntlFormData = [
    { id: 'email', type: 'email', label: intl.formatMessage({ id: 'login-form-email-label' }), placeholder: intl.formatMessage({ id: 'login-form-email-placeholder' }) },
    { id: 'password', type: 'password', label: intl.formatMessage({ id: 'login-form-password-label' }), placeholder: intl.formatMessage({ id: 'login-form-password-placeholder' }) }
  ]

  const loginIntlErrors = {
    email_invalid: intl.formatMessage({ id: 'login-form-email-error-invalid' }),
    email_required: intl.formatMessage({ id: 'login-form-email-error-required' }),
    password_required: intl.formatMessage({ id: 'login-form-password-error-required' })
  }

  const loginIntlButtons = {
    buttonSend: intl.formatMessage({ id: 'button-login' })
  }

  const loginInitialValues = {
    email: '',
    password: ''
  }

  const loginFormMessages = {
    submittingMessage: intl.formatMessage({ id: 'login-form-sending-form-message' }),
    successMessage: intl.formatMessage({ id: 'login-form-success-message' })
  }

  return { loginIntlFormData, loginIntlErrors, loginIntlButtons, loginInitialValues, loginFormMessages }
}
