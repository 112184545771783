const loginForm = {
  'login-form-email-label': 'E-Mail-Adresse',
  'login-form-email-placeholder': 'Ihre E-Mail-Adresse hier...',
  'login-form-email-error-invalid': 'Ungültige E-Mail-Adresse',
  'login-form-email-error-required': 'E-Mail ist erforderlich',
  'login-form-password-label': 'Passwort',
  'login-form-password-placeholder': 'Ihr Passwort hier...',
  'login-form-password-error-required': 'Passwort ist erforderlich',
  'login-form-sending-form-message': 'Benutzer wird gerade eingeloggt, bitte warten',
  'login-form-success-message': 'Benutzer erfolgreich angemeldet'
}

export default loginForm
