import { useEffect, useRef, useState } from 'react'
import { PanditoCharriotCateringShort } from '../../../images/Catering/Catering'
import { FormattedMessage } from 'react-intl'
import CateringForm from './CateringForm'
import { PanditoChariotIcon } from '../../../images/Icons/icons'
import WhiteButton from '../../atoms/buttons/WhiteButton'

const Catering = () => {
  const [showForm, setShowForm] = useState(false)
  const formRef = useRef(null)

  useEffect(() => {
    if (showForm && formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [showForm])

  return (
    <div className='template'>
      <div className='top-template-image-container'>
        <img src={PanditoCharriotCateringShort} className='top-template-image' alt='pandito' />
        <h1 className='custom-orange-h1'>
          <FormattedMessage id='sliderItems-4-title' />
        </h1>
      </div>
      <div className='template-box'>
        <p className='custom-black-p'>
          <FormattedMessage id='Catering-1' />
          <br />
          <FormattedMessage id='Catering-2' />
          <br />
          <FormattedMessage id='Catering-3' />
          <br />
          <br />
          <FormattedMessage id='Catering-4' />
          <br />
          <FormattedMessage id='Catering-5' />
          <span style={{ fontWeight: '900' }}>
            <FormattedMessage id='Catering-6' />
          </span>
        </p>
        <div className='catering-button-show-form' onClick={() => setShowForm(!showForm)}>
          <WhiteButton type='button'>
            <div className='pandito-icon-map'>
              <img src={PanditoChariotIcon} alt='pandito-icon-map' />
            </div>
            <FormattedMessage id='Catering-show-form' />
          </WhiteButton>
        </div>
        {showForm
          ? (
            <CateringForm ref={formRef} />
            )
          : (
              null
            )}
      </div>
      <div className='bottom-spacer' />
    </div>
  )
}

export default Catering
